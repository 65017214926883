import GooglePayButton from '@google-pay/button-react';
import { usePaymentContext } from 'context/PaymentContext';
import useGooglePay, { isProductionGoogle } from 'libs/useGooglePay';
import { useWalletPayStore } from 'libs/useWalletPay';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setSubmiting } from 'reducers/payments';
import useRudderStack from 'utils/useRudderStack';
export function GooglePayConfiguredButton({}) {
  const { sendPayment }  = useGooglePay();
  const dispatch = useDispatch();
  const { rudderStack, ready: readyRudderStack } = useRudderStack();
  const getPaymentRequest = (amount, handle) => {
    return {
      apiVersion: 2,
      apiVersionMinor: 0,
      allowedPaymentMethods: [
        {
          type: 'CARD',
          parameters: {
            allowedAuthMethods: ['CRYPTOGRAM_3DS'],
            allowedCardNetworks: ['VISA', 'MASTERCARD'],
            assuranceDetailsRequired: true,
            billingAddressRequired: true,
            billingAddressParameters: {
              phoneNumberRequired: true,
            },
          },
          tokenizationSpecification: {
            type: 'PAYMENT_GATEWAY',
            parameters: {
              gateway: 'cloudwalk',
              gatewayMerchantId: handle,
            },
          },
        },
      ],
      emailRequired: true,
      merchantInfo: {
        merchantName: handle,
        merchantId: 'BCR2DN4T5HY7XOLZ'
      },
      transactionInfo: {
        totalPriceStatus: 'FINAL',
        totalPriceLabel: 'Total',
        totalPrice: amount,
        currencyCode: 'BRL',
        countryCode: 'BR',
      }
    }
  }
  const { params } = usePaymentContext()
  const amount = useWalletPayStore((state) => state.amount);

  const [paymentRequest, setPaymentRequest] = useState(getPaymentRequest(amount, params?.handle));
  useEffect(() => {
    setPaymentRequest(getPaymentRequest(amount, params?.handle))
  }, [amount, params?.handle])

  useEffect(() => {
    setPaymentRequest(getPaymentRequest(amount, params?.handle))
  }, [amount])

  const buttonClicked = useCallback(() => {
    readyRudderStack && rudderStack?.track(`Payment Link | GooglePay | Button Clicked`)
    dispatch(setSubmiting(true))
  }, [readyRudderStack]);
  
  const cancelClicked = useCallback(() => {
    readyRudderStack && rudderStack?.track(`Payment Link | GooglePay |  Payment Closed`)
    dispatch(setSubmiting(false))
  }, [readyRudderStack]);

  const errorReturned = useCallback(() => {
    readyRudderStack && rudderStack?.track(`Payment Link | GooglePay |  Error Payment`)
    dispatch(setSubmiting(false))
  }, [readyRudderStack]);

  return <GooglePayButton 
    environment={isProductionGoogle()}
    paymentRequest={paymentRequest}
    buttonSizeMode="fill"
    buttonRadius="24"
    style={{ width: '100%', height: 48 }}
    onClick={() => buttonClicked()}
    onCancel={() => cancelClicked()}
    onError={() => errorReturned()}
    onLoadPaymentData={async (paymentData) => {
      try {
        dispatch(setSubmiting(true))
        return await sendPayment(paymentData)
      } catch (e) {
        console.log(e)  
        return { transactionState: "ERROR" };
      }
    }}
  />

}