  import Script from "next/script";
import { ApplePayButton, GooglePayButton } from "components/ui/form";
import { styled } from '@material-ui/core/styles'
import config from "config";
import { selectPayments, setError } from "reducers/payments";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useState } from "react";
import { InstallmentsModal } from "./InstallmentsModal";
import { ErrorModal } from "./ErrorModal";

import { Box } from "@material-ui/core";
import useRudderStack from "utils/useRudderStack";
import { useShallow } from "zustand/react/shallow";

import { create } from 'zustand'
import { produce } from "immer";
import { usePaymentContext } from "context/PaymentContext";
import useInstallments from "utils/useInstallments";
import { set } from "date-fns";
import { useApplePayStore } from "libs/useApplePay";
import { useGooglePayStore } from "libs/useGooglePay";
import { LABELS, useWalletPayStore } from "libs/useWalletPay";


export const AppleButtonBox = styled(Box)(
  ({ theme, ...props }) => {
    return {
      width: 279,
      margin: '0 auto',
      [theme.breakpoints.up('md')]: {
        width: 470,
      },
    }
  },
  {
    name: 'AppleButtonBox',
  },
)

export const GoogleButtonBox = styled(Box)(
  ({ theme, ...props }) => {
    return {
      width: 279,
      margin: '0 auto',
      [theme.breakpoints.up('md')]: {
        width: 470,
      },
    }
  },
  {
    name: 'GoogleButtonBox',
  },
)

export const installmentPopupStore = create((set) => ({
  showInstallmentPopup: false,
  setShowInstallmentPopup: (show) => set(produce((state) => { state.showInstallmentPopup = show})),
}))

export function WalletContainer({}) {
  const {appleCanMakePayment} = useApplePayStore(useShallow((state) => ( {appleLoading: state.loading, appleCanMakePayment: state.canMakePayment})));
  const {googleCanMakePayment} = useGooglePayStore(useShallow((state) => ( {googleLoading: state.loading, googleCanMakePayment: state.canMakePayment})));
  const {setWalletType, walletType, installment, setInstallment, setAmount: setWalletAmount} = useWalletPayStore(useShallow((state) => ( {setWalletType: state.setWalletType, installment: state.installment, setInstallment: state.setInstallment, setAmount: state.setAmount})));
  const {showInstallmentPopup, setShowInstallmentPopup} = installmentPopupStore(useShallow((state) => (
    {
      showInstallmentPopup: state.showInstallmentPopup,
      setShowInstallmentPopup: state.setShowInstallmentPopup
    }
  )))

  const dispatch = useDispatch()
  const payments = useSelector(selectPayments)
  const { rudderStack, ready: readyRudderStack } = useRudderStack()
  const { amount, valueWithFees, transferFees, setAmount } = usePaymentContext()
  const { installmentsData } = useInstallments({baseAmount: amount})
  const onPopupInstallmentClose = useCallback(() => {
    readyRudderStack && rudderStack?.track(`Payment Link | ${LABELS[walletType]} | Installments Modal Closed`)
    setShowInstallmentPopup(false)
  }, [setShowInstallmentPopup, readyRudderStack])
  const handleInstallments = useCallback((walletType) => {
    setWalletType(walletType)
    dispatch(setError(null))

    readyRudderStack && rudderStack?.track(`Payment Link | ${LABELS[walletType]} | Installments Modal Opnened`)
    console.log('installment', installment) 
    if (installment == 'default') {
      const selectedInstallment = installmentsData[installmentsData.length - 1].value
      setInstallment(installmentsData[selectedInstallment].value)
      console.log('installmentsData', transferFees && selectedInstallment > 0)
      if (transferFees && selectedInstallment > 0) {
        setAmount(valueWithFees[selectedInstallment-1].total_value)
        setWalletAmount(valueWithFees[selectedInstallment-1].total_value)
      }
    } else if (valueWithFees) {
      setAmount(valueWithFees[installment-1].total_value)
      setWalletAmount(valueWithFees[installment-1].total_value)
    } else {
      setAmount(amount)
      setWalletAmount(amount)
    }
    setShowInstallmentPopup(true)
  }, [setShowInstallmentPopup, readyRudderStack, installment, setInstallment, installmentsData, setAmount, valueWithFees, transferFees, setWalletAmount])

  return <>
    <Script async src={`https://www.google.com/recaptcha/api.js?render=${config.recaptchaSiteKey}`} />
    {
      appleCanMakePayment && <AppleButtonBox>
        <ApplePayButton onClick={() => handleInstallments('apple_pay')} submitting={payments.fetching} >Pagar com </ApplePayButton>  
      </AppleButtonBox>
    }
    {
      !appleCanMakePayment && googleCanMakePayment && <GoogleButtonBox>
        <GooglePayButton onClick={() => handleInstallments('google_pay')} submitting={payments.fetching} >Pagar com </GooglePayButton>  
      </GoogleButtonBox>
    }
    <InstallmentsModal  open={showInstallmentPopup && !payments.error} onClose={onPopupInstallmentClose}/> 
    <ErrorModal />
  </>;
}
