import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { format } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import cardValidator from 'card-validator'
import behavioural from 'libs/behavioural'
import linkApi, { AUTHENTICATOR_DEFAULT } from 'libs/infinitepay/link'
import { cleanEmail, signIn } from './session'
import { AUTHORIZATION_CODE, showFormPost } from './threeds'
import { startUnicoPay } from './unicopay'
import { redirectToReceipt } from './redirectReceipt'
import config from 'config'
import { captureException } from '@sentry/nextjs'

export const createPayment = createAsyncThunk(
  'payments/createPayment',
  async (payload, { getState, dispatch, rejectWithValue }) => {
    const { handle, amount, rudderStack, params, payload: paymentPayload } = payload
    const session = getState().session
    if (session.email_error) {
      dispatch(cleanEmail())
      return rejectWithValue('Email já foi utilizado por outro telefone')
    }

    const phoneNumber = paymentPayload.billing_details.phone_number
    let user = session.user
    let response
    if (!user || user.phone_number !== phoneNumber) {
      try {
        response = await dispatch(
          signIn({
            phoneNumber: phoneNumber,
            email: paymentPayload.billing_details.email,
            authenticator: AUTHENTICATOR_DEFAULT,
            rudderStack,
            params
          }),
        )
        const { payload } = response
        const { user: resultAsUser } = payload
        if (resultAsUser) {
          user = resultAsUser
        } else {
          if (typeof payload !== 'string') {
            return {
              signing: true,
              error: null,
            }
          }
          return rejectWithValue({
            signing: true,
            error: payload,
          })
        }
      } catch (err) {
        if (!err.response) {
          return rejectWithValue(err.message)
        } else {
          return rejectWithValue(err.response.data || err.message)
        }
      }
    }

    const userHandle = user?.handle
    try {
      if (userHandle === handle) {
        throw new Error('501')
      }
      response = await linkApi.pay(handle, amount, paymentPayload)
      if (!response) {
        return rejectWithValue('not_happened')
      }
      const attrs = response.data?.attributes
      const code = attrs?.authorization_code || undefined

      const receipt = getReceipt(user, paymentPayload, attrs, amount, handle, response)
      if (code.toUpperCase() === AUTHORIZATION_CODE.THREEDS && attrs.three_ds) {
        behavioural.finish()

        let params3DS = { transaction: { nsu: attrs?.nsu, receipt: receipt, handle: handle } }
        if (attrs.three_ds.event === 'auth_request') {
          params3DS.form = {
            action: attrs.three_ds.device_data_collection_url,
            jwt: attrs.three_ds.access_token,
          }
        } else if (attrs.three_ds.event === 'enrollment') {
          params3DS.challenge = {
            source: attrs.three_ds.challenge_url,
            width: attrs.three_ds.width,
            height: attrs.three_ds.height,
            jwt: attrs.three_ds.access_token,
          }
        }
        const check = await dispatch(showFormPost(params3DS))
      } else if (code.toUpperCase() === AUTHORIZATION_CODE.UNICOPAY) {
        behavioural.finish()

        let paramsUnico = {
          transaction: { nsu: attrs?.nsu, receipt: receipt, handle: handle },
          unicopay: attrs.unico_idpay,
        }

        await dispatch(startUnicoPay(paramsUnico))
      } else if (code !== AUTHORIZATION_CODE.ACCEPTED) {
        behavioural.addFailedAttempts()
        linkApi.setNSU()
        throw new Error(code)
      } else {
        behavioural.finish()
        dispatch(hideForm())
        dispatch(redirectToReceipt(`${config.receiptsURL}/${receipt?.transactionId}`))
      }
      return response.data || response
    } catch (err) {
      if (!err.response) {
        return rejectWithValue(err.message)
      } else {
        return rejectWithValue(err?.response.data || err?.message)
      }
    }
  },
)

export const createWalletPayment = createAsyncThunk(
  'payments/createWalletPayment',
  async (payloadThunk, { dispatch, rejectWithValue }) => {
    const { handle, amount, payload, ...rest } = payloadThunk

    try {
      const response = await linkApi.payWallet(handle, amount, payload)
      if (!response) {
        return rejectWithValue('not_happened')
      }

      const attrs = response.data?.attributes
      const code = attrs?.authorization_code || undefined

      if (code !== AUTHORIZATION_CODE.ACCEPTED) {
        behavioural.addFailedAttempts()
        linkApi.setNSU()
        throw new Error(code)
      } else {
        behavioural.finish()
        const nsu = response.data?.id || attrs?.nsu
        dispatch(hideForm())
        dispatch(redirectToReceipt(`${config.receiptsURL}/${nsu}`))
      }
      return response.data || response
    } catch (err) {
      captureException(err)
      if (!err.response) {
        return rejectWithValue(err.message)
      } else {
        return rejectWithValue(err?.response.data || err?.message)
      }
    }
  },
)

const paymentsSlice = createSlice({
  name: 'payments',
  initialState: {
    fetching: false,
    active: false,
    signing: null,
    transaction: null,
    error: null,
  },
  reducers: {
    showForm(state, action) {
      state.active = true
    },
    setSubmiting(state, action) {
      state.fetching = action.payload
    },
    setError(state, action) {
      state.error = action.payload
      state.fetching = false
    },
    hideForm(state) {
      state.active = false
    },
  },
  extraReducers: {
    [createPayment.pending]: (state, action) => {
      state.fetching = true
      state.signing = false
      state.error = null
    },
    [createPayment.fulfilled]: (state, action) => {
      const { payload } = action
      state.fetching = false
      state.error = null
      state.signing = !!payload?.signing
      state.transaction = !state.signing ? action.payload : null
    },
    [createPayment.rejected]: (state, action) => {
      const { payload } = action
      state.fetching = false
      state.transaction = null
      state.signing = !!payload?.signing
      state.error = payload?.error || payload
    },
    [createWalletPayment.pending]: (state, action) => {
      state.fetching = true
      state.signing = false
      state.error = null
    },
    [createWalletPayment.fulfilled]: (state, action) => {
      const { payload } = action
      state.fetching = false
      state.error = null
      state.transaction = action.payload
    },
    [createWalletPayment.rejected]: (state, action) => {
      const { payload } = action
      state.fetching = false
      state.transaction = null
      state.error = payload?.error || payload
    },
  },
})

const { actions, reducer } = paymentsSlice

export const selectPayments = (state) => state.payments

export const { showForm, completedForm, hideForm, setSubmiting, setError } = actions

export default reducer

function getReceipt(user, paymentPayload, attrs, amount, handle, response) {
  const fromHandle =
    user.handle ||
    user.email ||
    user.name ||
    paymentPayload.billing_details.email ||
    paymentPayload.billing_details.name
  const name = (user.merchant || user.cardholder)?.name || fromHandle
  const cardType = cardValidator.number(paymentPayload.card?.card_number || '')?.card?.type
  const date = new Date(attrs?.created_at)
  const params = {
    name: name,
    email: paymentPayload.billing_details.email || user.email,
    amount: amount,
    from: fromHandle,
    cardType: cardType,
    handle: handle,
    transactionId: response.data?.id || attrs?.nsu,
    date: format(date, 'dd MMM yyyy - HH:mm', { locale: ptBR }),
    installments: paymentPayload.payment.installments,
    cardNumberTampered: paymentPayload.card?.card_number?.substr(-4) || '',
  }
  return params
}
