import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { captureException } from '@sentry/nextjs'
import linkApi, {  AUTHENTICATOR_DEFAULT } from 'libs/infinitepay/link'

export const signIn = createAsyncThunk(
  'session/signIn',
  async ({ phoneNumber, name, email, authenticator, rudderStack, params }, { getState, rejectWithValue }) => {
    try {
      authenticator = authenticator || AUTHENTICATOR_DEFAULT
      rudderStack.track(`Payment Link - Token[${authenticator}] - Start`) 
      const session = getState().session
      
      const loggedUser = session.user
      if (loggedUser && loggedUser.phoneNumber === phoneNumber) {
        return {
          user: loggedUser,
        }
      } else {
        const moreParams = {
          handle: params.handle,
          path: params.amount,
          origin: 'link'
        }
        const auth = await linkApi.login(phoneNumber, authenticator, moreParams)
        if (auth.data && !auth.data.errors) {
          return {auth: {...auth.data, authenticator}, extraData: { name, email, phoneNumber }}
        }
        throw new Error(`AuthMessage: ${JSON.stringify(auth.data)}`)
      }
    } catch (err) {
      captureException(err)
      if (err.response ) { 
        if (err.response.status === 422) {
          return rejectWithValue(`Erro ao tentar validar seu número, por favor tente outro telefone`)
        } else if (err.response.status === 429) {
          return rejectWithValue(`Você já realizou muitas tentativas, por favor aguarde e tente novamente`)
        }
      }
      return rejectWithValue(`Erro ao tentar validar seu telefone, por favor tente novamente mais tarde`)
    }
  },
)

export const confirmToken = createAsyncThunk(
  'session/confirmToken',
  async ({ token, interaction_id, name, email, phoneNumber, rudderStack, params }, { getState, rejectWithValue }) => {
    const { confirmation: {authenticator}} = getState().session
    
    const notifierChannel = authenticator === 'sms' ? 'SMS' : 'WhatsApp'
    try {
      
      rudderStack.track(`Payment Link - Token[${authenticator}] - Check`) 
      
      const moreParams = {
        handle: params.handle,
        path: params.amount,
        origin: 'link'
      }
      const validatePayload = await linkApi.validateToken(token, interaction_id, phoneNumber, email, moreParams)
      
      
      if (typeof validatePayload.data  === 'object') {
        const jwtResponse = await linkApi.finishToken(validatePayload.data.interaction_id)
        if (!linkApi.validJWT(jwtResponse.data.token)) {
          throw new Error(`AuthMessage: ${token.split('.').length === 3 ? token.split('.')[1] : token}`)
        }
        linkApi.setAuthToken(validatePayload.data.interaction_id)
        rudderStack.track(`Payment Link - Token[${authenticator}] - Validated`) 
        
        linkApi.setAuthJWT(jwtResponse.data.token)
        return {
          user: {name: name, email: email, phone_number: phoneNumber},
        }
      } else {  
        throw new Error(`AuthMessage: ${JSON.stringify(validatePayload)}`)
      }
    } catch (err) {
      captureException(err)
      if (err.response ) { 
        if (err.response.status === 401) {
          return rejectWithValue(`O código ${notifierChannel} está inválido ou expirado. Reenvie o código de verificação para tentar novamente.`)
        }
        else if (err.response.status === 422) { 
          throw rejectWithValue(`Oops, tivemos um problema na validação. Entre em contato com a InfinitePay.`)
        }
        else if (err.response.status === 429) { 
          return rejectWithValue(`Você já realizou muitas tentativas, por favor aguarde e tente novamente`)
        } else {
          return rejectWithValue(`Erro ao tentar validar seu telefone, por favor tente novamente mais tarde`)
        }
      }
      return rejectWithValue('O código ${notifierChannel} está inválido ou expirado. Reenvie o código de verificação para tentar novamente.')
    }
  },
)

export const reToken = createAsyncThunk(
  'session/reToken',
  async ({ phoneNumber, email, authenticator }, { rejectWithValue }) => {
    try {
      authenticator = authenticator || AUTHENTICATOR_DEFAULT
      const auth = await linkApi.login(phoneNumber, authenticator)
      if (auth.data && !auth.data.errors) {
        return {auth: {...auth.data, authenticator}, extraData: { name, email, phoneNumber }}
      }
      return rejectWithValue(JSON.stringify(auth.data.errors));
    } catch (err) {      if (err.response && err.response.status === 429) { 
        return rejectWithValue(`Você já realizou muitas tentativas, por favor aguarde e tente novamente`)
      }
      if (err.response ) { 
        return rejectWithValue(`Erro ao tentar validar seu telefone, por favor tente novamente mais tarde`)
      }
      return rejectWithValue(err?.message)
    }
  },
)

const sessionSlice = createSlice({
  name: 'session',
  initialState: {
    fetching: false,
    email_error: false,
    confirmation: null,
    user: null,
    receiver: null,
    error: null,
  },
  reducers: {
    cleanEmail(state) {
      state.email_error = false
    },
  },
  extraReducers: {
    [signIn.pending]: (state, action) => {
      state.fetching = true
      state.user = null
      state.extraData = null
      state.error = null
    },
    [signIn.fulfilled]: (state, action) => {
      const {  extraData, user, auth } = action.payload || {}
      state.error = null
      state.fetching = false
      state.extraData = extraData
      if (user) {
        state.confirmation = null
        state.user = user
      } else {
        state.confirmation = auth
      }
    },
    [signIn.rejected]: (state, action) => {
      state.fetching = false
      state.confirmation = null
      
      state.error = action.payload
    },
    [reToken.pending]: (state, action) => {
      state.fetching = true
      state.error = null
    },
    [reToken.fulfilled]: (state, action) => {
      const { auth, extraData } = action.payload || {}
      state.error = null
      state.fetching = false
      state.extraData = extraData
      state.confirmation = auth
    },
    [reToken.rejected]: (state, action) => {
      state.fetching = false
      state.error = action.payload?.message || action.payload
    },
    [confirmToken.pending]: (state, action) => {
      state.email_error = false
      state.fetching = true
      state.error = null
    },
    [confirmToken.fulfilled]: (state, action) => {
      state.email_error = false
      const { user } = action.payload || {}
      state.fetching = false
      state.confirmation = null
      if (user) {
        state.user = user
        state.error = null
      } else {
        state.error = user?.error || action.payload
      }
    },
    [confirmToken.rejected]: (state, action) => {
      state.fetching = false
      state.error = action.payload?.message || action.payload
      state.email_error = state.error.startsWith('Email ');
    },
  },
})

const { actions, reducer } = sessionSlice

export const selectSession = (state) => state.session

export const { cleanEmail } = actions

export default reducer
