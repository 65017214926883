import { create } from 'zustand'
import { produce } from 'immer'


export const SHARED_PAYMENT_MESSAGE = 'shared'

const useUnicoIdPayStore = create((set) => ({
  thirdPerson: false,
  setThirdPerson: (thirdPerson) => set(produce((state) => { state.thirdPerson = thirdPerson}))
}))

const useUnicoIdPay = () => {
  const thirdPerson= useUnicoIdPayStore((state) => state.thirdPerson)
  return {
    thirdPerson
  }
}

export default useUnicoIdPay;
export { useUnicoIdPayStore };
